let app;

import { createApp, ref } from "vue";

import App from "./App.vue";

import router from "./router";
import store from "./store";
import { FormKit } from "./plugins/FormKitCustom";
import Vue3Transitions from "vue3-transitions";

import "@styles/main.scss";
import "bootstrap/dist/js/bootstrap.js";

// import { fb, fbApp, auth, db, user, rtdb, cxce } from "@use/useFirebase";
// import { onAuthStateChanged } from "firebase/auth";
const starting = ref(false);

// watch(user, () => start(), { immediate: true });

import BaseField from "./components/base/BaseField.vue";
import BaseContainer from "./components/base/BaseContainer.vue";

function start() {
  if (!starting.value && !app) {
    starting.value = true;
    app = createApp(App);
    app.use(router);
    app.use(store);
    app.use(Vue3Transitions);
    const formKitConfig = FormKit.defaultConfig(FormKit.formKitConfig);

    app.use(FormKit.plugin, formKitConfig);
    app.component("BaseField", BaseField);
    app.component("BaseContainer", BaseContainer);
    app.mount("#app");
  } 
}
start();
// onAuthStateChanged(auth, (udata) => {
// //   console.log("udata", udata || null);
// });
// fb, fbApp, auth, db, user, rtdb, cxce, 
export { app,store, router,app as default };

// export default app;
