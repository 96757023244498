import { ref } from "vue";
import { createStore } from "vuex";
import { storage } from "@use/storage";

import { DateTime } from "luxon";
import VuexPersist from "vuex-persist";
import { randomBytes } from "crypto";

import appMessagesModule from "./appMessages";

const restoredAt = ref(false);

export const store = createStore({
  state: {
    wpu: {},
  },
  mutations: {
    SET_WPU(state, wpu) {
      state.wpu = wpu;
    },
  },
  actions: {
    setWpu({ commit, state }, form) {
      commit("SET_WPU", isRef(form) ? form.value : form);
    },
  },
  getters: {},
  modules: { appMessages: appMessagesModule },
  //   plugins: [usePersist()],
});

// export const store = createStore({
//   state: {
//     wpu: {},
//     appMessages: [],
//   },
//   mutations: {
//     SET_WPU(state, wpu) {
//       state.wpu = wpu;
//     },
//     ADD_APP_MESSAGE(state, message) {
//       const stateIndex = state.appMessages.length;
//       state.appMessages.unshift({
//         ...message,
//         stateIndex,
//       });
//     },
//     DISMISS_APP_MESSAGE(state, id) {
//       try {
//         const index = state.appMessages.findIndex((m) => m.id === id);
//         if (index > -1) {
//           state.appMessages[index].dismissedAt = DateTime.local().toMillis();
//         }
//       } catch (error) {
//         console.warn(error);
//       }
//     },
//   },
//   actions: {
//     setWpu({ commit, state }, form) {
//       commit("SET_WPU", isRef(form) ? form.value : form);
//     },
//     addAppMessage({ commit }, payload = {}) {
//       // console.log("addAppMessage", payload);
//       const id = `message_${DateTime.local().toMillis()}${randomBytes(
//         16
//       ).toString("hex")}`;
//       const title =
//         payload.title ||
//         `App${process.env.NODE_ENV != "production" ? "" : " (DEFAULT)"}`;
//       const body = payload.body || "";
//       const timeout =
//         typeof payload.timeout == "number" ? payload.timeout : false;
//       const createdAt = DateTime.local().toMillis();
//       const dismissedAt = false;
//       const message = {
//         title,
//         body,
//         timeout,
//         createdAt,
//         dismissedAt,
//         timeout,
//         id,
//       };
//       commit("ADD_APP_MESSAGE", message);
//       if (!!timeout) {
//         setTimeout(() => {
//           commit("DISMISS_APP_MESSAGE", id);
//         }, timeout);
//       }
//     },
//     dismissAppMessage({ commit }, id) {
//       commit("DISMISS_APP_MESSAGE", id);
//     },
//   },
//   getters: {
//     getAppMessages(state) {
//       const messages = state.appMessages;
//       const notDismissed = messages.filter((m) =>
//         m.dismissedAt && m.dismissedAt > 0 ? false : true
//       );
//       console.log("notDismissed", notDismissed);
//       return notDismissed;
//     },
//   },
//   modules: {},
//   //   plugins: [usePersist()],
// });
export default store;

function usePersist() {
  return new VuexPersist({
    key: "mxcxcestate2",
    restoreState: (key) => {
      const $state = storage.get(key) ? storage.get(key) : undefined;
      setTimeout(() => {
        restoredAt.value = DateTime.now().toMillis();
      }, 500);
      return $state;
    },
    saveState: (key, state) => {
      if (
        restoredAt.value &&
        restoredAt.value < DateTime.now().toMillis() - 250
      ) {
        storage.set(key, state);
      }
    },
  }).plugin;
}
// export default store;
// export { store }

// // plugins: [usePersist()],
// //     state: {

// //         forms: {
// //             project: {
// //                 id: 'wpu',
// //                 // form: null,
// //                 errors: null,
// //                 projects: {},
// //                 projectId: null

// //             },
// //             client: {},
// //             contact: {
// //                 clientId: null,
// //                 id: UUID(),
// //                 name: null,
// //                 email: null,
// //             },
// //             user: {
// //                 id: UUID(),
// //                 name: null,
// //                 email: null,
// //             }
// //         },
// //         user: {
// //             id: UUID(),
// //             name: null,
// //             email: null,
// //         },
// //         projects: {},
// //         clients: {},
// //         contacts: {},
// //         projectId: null,
// //         clientId: null,
// //         contactId: null,

// //         // clients: {
// //         //     example_client: {
// //         //         id: 'example_client',
// //         //         name: 'Example Client',
// //         //         contacts: {
// //         //             example_contact: {

// //         //                 id: 'example_contact',
// //         //                 name: 'Example Client',
// //         //                 primary: true,
// //         //                 email: 'contact@example.com'

// //         //             }
// //         //         },
// //         //         contactId: 'example_contact',
// //         //     }
// //         // }
// //     },
// //     getters: {
// //         // contact: (state) => ({ contactId, id }) => {
// //         //     const client = state.clients[id] || false;
// //         //     if (!client) return null;
// //         //     const contact = client && client.contacts && client.contacts[contactId] ? client.contacts[contactId] : null;
// //         //     return contact;
// //         // },
// //         // client: (state) => (client) => {
// //         //     if (!client || !client.id || !state.clients[client.iod]) return null;
// //         //     const c = state.clients[client.id];
// //         //     const contact = store.getters.contact(c);
// //         //     return { ...c, contact: state, contact }
// //         // },
// //         // clients: (state) => {
// //         //     const result = {}
// //         //     for (var id in state.clients) {
// //         //         const c = state.clients[id];
// //         //         const client = store.getters.client(c);
// //         //         result[id] = client;
// //         //     }
// //         //     return result;
// //         // },
// //         form: (id = 'wpu') => (state) => {
// //             if (typeof state.forms[id] == 'undefined') {
// //                 return null;
// //             } else {
// //                 return state.forms[id];
// //             }
// //         }
// //     },
// //     mutations: {
// //         SET_ITEM(state, { parent, id, form }) {
// //             state[parent.id][id] = { ...form, parentId: parent.id };
// //         },
// //         SET_FORM(state, payload = { id: null, form: null }) {
// //             const { id, form } = payload;
// //             if (id && typeof form != 'undefined') {
// //                 state.forms[id] = { ...form, formId: id, id, updatedAt: TS() };
// //             }
// //         },
// //         SET_USER(state, user = null) {
// //             state.user = user;
// //         },
// //         SET_CLIENT(state, { id = UUID(), value = null }) {

// //             state.client[id] = value;
// //             // if(project && typeof project.id != 'undefined' && typeof project.value !='undefined'){ {
// //             //     const {id,value} = project;
// //             // }
// //         },
// //     },
// //     actions: {
// //         setForm({ commit }, payload = { id: null, form: null }) {
// //             try {
// //                 commit('SET_FORM', payload);
// //             } catch (e) {
// //                 console.info('failed at setForm', e);
// //             }
// //         },
// //         setProject({ state, commit }, { id = UUID(), value = null }) {
// //             const payload = { id: id || UUID(), value: typeof value != 'undefined' ? value : null }
// //             commit('SET_PROJECT', payload);
// //             return state.projects[id]
// //         },
// //         setUser({ state, commit }, user) {

// //             commit('SET_USER', user)
// //             return state.user;
// //         },
// //         setClient(state, commit, { id = UUID(), value = null }) {
// //             commit('SET_CLIENT', { id, value });
// //             return state.client[payload.id];
// //         },
// //         setContact({ state, dispatch }, { clientId, id = UUID(), value = null }) {
// //             const contacts = { ...state.clients[clientId].contacts, [id]: { ...value, clientId, id } };
// //             const client = { ...state.clients[clientId], contacts };
// //             return dispatch('setClient', { id: clientId, value: client });
// //         },
// //         setForm({ state, commit }, payload = { id: null, form: null }) {
// //             const defaults = () => ({ id: UUID(), value: null });
// //             commit('SET_FORM', { id: payload.id, form: payload.value });

// //             let { id, value, formId } = { ...defaults, ...payload };
// //             if (!id) {
// //                 id.value = UUID();
// //                 if (typeof value == 'undefined') {
// //                     value = null;
// //                 }
// //             }
// //             if (!formId) {
// //                 console.warn('formId @ store.dispatch(setForm,{id,value,formId}) is required');
// //                 return;
// //             } else {
// //                 commit('SET_FORM', { id, value })
// //                 return state.forms[formId]
// //             }
// //         }
// //     },
// //     // modules: {
// //     // },
// //     plugins: [usePersist().plugin]
// // })
// // // try {
// // //     storage.removeAll()
// // // } catch (error) {
// // //     // console.info('error clearing', error)
// // // }
