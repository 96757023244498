// import WeeklyProjectUpdate2Vue from "./WeeklyProjectUpdate2.vue"
const Tools = () => {
  const t = [{
    id: 'weekly-project-update',
    icon: "fa-solid fa-at",
    title: 'Weekly Project Update Email Generator',
    componentName: 'WeeklyProjectUpdate',
    description: 'Use a template to and download html for sending project update emails'
  }, {
    id: 'mx-connect-config-generator',
    icon: "fa-solid fa-cog",
    title: 'Connect Widget Config Generator',
    componentName: 'MxConnectConfigGenerator',
    description: 'Use to generate a config file for MXconnect'
  }]; // if (process.env.NODE_ENV != 'production') {
  //     t.push({
  //         id: 'weekly-project-update-2',
  //         icon: "fa-solid fa-at",
  //         componentName: 'WeeklyProjectUpdate2',
  //         title: 'Weekly Project Update Email Generator',
  //         description: 'Use a template to and download html for sending project update emails'
  //     })
  // }

  return t;
};

export const tools = Tools();
export default {
  data() {
    return {
      tools
    };
  }

};