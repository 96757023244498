import { computed } from "vue";
import { store } from "@/store/index.js";
// // console.log("store", store);
export const create = (payload) => store.dispatch("addAppMessage", payload);
export const dismiss = (id) => store.dispatch("dismissAppMessage", id);

export const get = (id) => {
  const all =
    store.state.appMessages?.length > 0 ? store.state.appMessages : null;
  if (!id) {
    return all;
  } else {
    const res = !all ? null : all.find((m) => m.id === id);
    return !!res ? res : null;
  }
};

export const messages = computed(get);
export const unread = computed(() => {
  const all = get();
  if (!all) return false;
  const unreadArr = all.filter((m) => !m.dismissedAt);
  if (unreadArr?.length > 0) {
    return unreadArr;
  } else {
    return null;
  }
});
export const unreadCount = computed(() =>
  unread.value && unread.value.length ? unread.value.length : 0
);
export const clearAll = () => {
  if (!unreadCount.value > 0) {
    // // console.log("no unread messages to clear");
  } else {
    unread.value.forEach((m) => {
      dismiss(m.id);
    });
  }
};

export const hasMessages = computed(() =>
  messages.value ? messages.value.length : null
);
export const count = computed(() =>
  hasMessages.value ? hasMessages.value : 0
);
export const isDismissed = (message) => {
  return message.dismissedAt ? true : false;
};

const ro = readonly({
  state: {
    count,
    hasMessages,
    messages,
  },
});
// const state = ro.state;
// // console.log(ro, ro.state, state);

export const useAppMessages = () => {
  return {
    create,
    dismiss,
    get,

    clearAll,
    messages,
    hasMessages,
    count,
    isDismissed,
    useAppMessages,
  };
};
export default useAppMessages();
