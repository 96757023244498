import * as crypto from "crypto";
import { nanoid } from "nanoid";
import { DateTime, Settings } from "luxon";
Settings.defaultZone = "America/Denver";
export const dt = DateTime;
const dtformatstr = "LLL. dd, yyyy";

export const readableDate = (value = dt.now()) => {
  return parseDate(value).toFormat(dtformatstr);
};
export const fromReadableDate = (value = readableDate()) =>
  DateTime.fromFormat(value, dtformatstr);
export const timestamp = (formatMethod = "toMillis") =>
  dt.now()[formatMethod || "toMillis"]();
export const TS = timestamp;
export const ts = timestamp;

import serialize from "serialize-javascript";
export const randomString = (n = 16) => {
  n = n && n > 4 ? n : 16;
  return crypto.randomBytes(n).tocreateString("hex");
};
export const createString = randomString;
export const createId = () => nanoid() + "_" + timestamp();
export const uuid = createId;
export const UUID = createId;
export const randomUUID = createId;
export const randomId = createId;
export const createKey = () => randomString(16);
export const createSecret = () => randomString(16);
export const createIv = () => randomString(32);
export function stringify(DATA) {
  const obj = { DATA };
  const str = serialize(obj, {
    isJSON: false,
    space: 4,
    ignoreFunction: true,
    unsafe: false,
  });
  return str;
}
export function parse(str) {
  try {
    if (typeof str !== "string" || !str.includes("DATA")) {
      console.info(
        "this was not stringified with serialize-javascript-found in useKit().stringify(value)"
      );
      if (typeof str !== "string") {
        console.info(
          "the value passed to useKit().parse(str) was not string. We are returning the value."
        );
        return str;
      } else {
        const objFromJson = JSON.parse(str);
        const valueFromJson =
          objFromJson && objFromJson.DATA ? objFromJson.DATA : objFromJson;
        return valueFromJson;
      }
    } else {
      const obj = eval("(" + str + ")");
      const value = obj && obj.STRINGIFY ? obj.STRINGIFY : obj;
      return value;
    }
  } catch (error) {
    console.info("ERROR parsing string from useKit().parse(str)");
    return null;
  }
}
export const utils = {
  serialize,
  randomString,
  createString,
  createId,
  uuid,
  UUID,
  randomUUID,
  randomId,
  createKey,
  createSecret,
  createIv,
  parse,
  stringify,
  fromReadableDate,
  readableDate,
};

export default utils;
export function parseDate(VALUE_TO_PARSE = null) {
  if (!VALUE_TO_PARSE) {
    return dt.now();
  }
  const methods = {
    fromISO: handler({
      method: DateTime.fromISO,
      nextMethodId: "fromReadabble",
    }),
    fromReadable: handler({
      method: (value) => DateTime.fromFormat(value, dtformatstr),
      nextMethodId: "fromString",
    }),
    fromMillis: handler({ method: DateTime.fromMillis, nextMethodId: false }),
  };
  function handler(method, nextMethodId) {
    //   console.log(method);
    return (value) => {
      //   console.log(value);
      const nextMethod =
        !nextMethodId || !methods[nextMethodId]
          ? null
          : methods[nextMethodId](value);
      if (dt.isDateTime(value)) return value;
      try {
        const res = method(value);
        if (dt.isDateTime(res)) {
          return res;
        } else {
          return nextMethod ? nextMethod(value) : dt.now();
        }
      } catch (error) {
        console.warn(error);
        return !nextMethod ? dt.now() : nextMethod(value);
      }
    };
  }
  return methods.fromISO(VALUE_TO_PARSE);
}
