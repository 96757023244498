import { plugin, defaultConfig } from "@formkit/vue";
import rootClasses from "./rootClasses.js";


const FormKitCustom = {
  plugin,
  formKitConfig: {
    config: {
      rootClasses,
    },
  },

  defaultConfig,
};
export { FormKitCustom as default, FormKitCustom as FormKit, FormKitCustom };
