import { useTitle as $useTitle } from "@vueuse/core";

export const defaultTitle = ref("Home");
export const titleSuffix = ref(" - MXCXCE ToolKit - MX");
export const title = $useTitle(defaultTitle.value + titleSuffix.value); // currentTitle.value = x => sets page title to x
export const setTitle = (str = "", withSuffix = true) => {
  let result;
  if (withSuffix) {
    try {
      result = str.replace(titleSuffix.value, "");
      title.value = result + titleSuffix.value;
    } catch {
      result = str + titleSuffix.value;
      title.value = result;
    }
  } else {
    title.value = str;
  }
  return title.value;
};
export const set = setTitle;
export const getTitle = () => {
  return title.value;
};
export const get = getTitle;

export const useTitle = (str, withSuffix = true) => {
  if (typeof str == "string") {
    setTitle(str);
  }
  return {
    title,
    getTitle,
    setTitle,
    get,
    set,
    defaultTitle,
    titleSuffix,
    $useTitle,
  };
};

export { $useTitle };
