// import * as crypto from 'crypto';
import $cookies from "js-cookie";
const ATTRS = () => ({
  expires: 365,
  domain: location.domain,
  sameSite: "strict",
  secure: !!(location.protocol === "https:"),
});
const COOKIES = $cookies.withAttributes(ATTRS());

const { set, remove } = COOKIES;
function get(key, value) {
  let cvalue = COOKIES.get(key) ? COOKIES.get(key) : null;
  if (!cvalue) {
    if (typeof value != "undefined") {
      set(key, value);
      return get(key);
    }
    return null;
  } else {
    return cvalue;
  }
}
const Cookies = { get, set, remove };
const useCookies = () => Cookies;

export { useCookies, Cookies, get, set, remove };
export default Cookies;
