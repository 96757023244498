import { computed } from 'vue';export default {
  props: {
    size: {
      type: String,
      required: false,

      default() {
        return 'small';
      }

    },
    maxWidth: {
      required: false,

      default() {
        return null;
      }

    }
  },
  computed: {
    style() {
      const sizes = {
        defaultSize: '100%',
        xxs: '400px',
        xs: '400px',
        small: '640px',

        get sm() {
          return this.small;
        },

        medium: '800px',

        get md() {
          return this.medium;
        },

        large: '1000px',

        get lg() {
          return this.large;
        },

        xl: '1200px',
        xxl: '100%',
        full: '100%',
        fs: '100%',
        fluid: '100%'
      };
      const sizeId = typeof this.size != 'undefined' ? this.size : null;
      const size = sizeId && typeof sizes[sizeId] != 'undefined' ? sizes[sizeId] : null;
      const mw = typeof this.maxWidth == 'string' ? this.maxWidth : typeof this.maxWidth == 'number' ? this.maxWidth + 'px' : null;
      const maxWidth = mw || size || sizes.defaultSize;
      return {
        minWidth: 0,
        width: '100%',
        maxWidth
      };
    }

  }
};