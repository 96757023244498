import { Cookies } from "./cookies";
import { ref } from "vue";
import Storage from "secure-ls";
import { randomBytes } from "crypto";
const $secret = ref(null);
const $storage = ref(null);

export const secret = "45025b888e17b113988e50066b292265";
export const storage = getStorage();
export const useStorage = () => storage;
export default storage;

function getSecret() {
  let value = Cookies.get("cxce.lssec");
  if (!value) value = randomBytes(16).toString("hex");
  Cookies.set("cxce.lssec", value, {
    expires: 365,
    domain: location.domain,
    sameSite: "strict",
    secure: true,
  });
  $secret.value = value;
  return $secret.value;
}
function getStorage() {
  $storage.value = new Storage({
    encodingType: "aes",
    isCompression: true,
    encryptionSecret: secret,
  });
  return $storage.value;
}
