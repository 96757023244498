import { DateTime } from "luxon";

import { randomBytes } from "crypto";

export default {
  state: [],
  mutations: {
    ADD_APP_MESSAGE(state, message) {
      const stateIndex = state.length;
      state.push({
        ...message,
        stateIndex,
      });
    },
    DISMISS_APP_MESSAGE(state, id) {
      try {
        const index = state.findIndex((m) => m.id === id);
        if (index > -1) {
          // console.log("DISMISS_APP_MESSAGE", { id });
          state[index].dismissedAt = DateTime.local().toMillis();
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
  actions: {
    addAppMessage({ commit, dispatch }, payload) {
      //   console.log("addAppMessage", payload);
      const id = `message_${DateTime.local().toMillis()}${randomBytes(
        16
      ).toString("hex")}`;
      const title = payload.title || null;

      const body = payload.body || null;
      const timeout =
        typeof payload.timeout == "number" ? payload.timeout : false;
      const createdAt = DateTime.local().toMillis();
      const dismissedAt = false;
      const message = {
        title,
        body,
        timeout,
        createdAt,
        dismissedAt,
        timeout,
        id,
      };
      commit("ADD_APP_MESSAGE", message);
      if (timeout && timeout > 0) {
        setTimeout(() => {
          commit("DISMISS_APP_MESSAGE", id);
        }, timeout);
      }
    },
    dismissAppMessage({ commit }, id) {
      commit("DISMISS_APP_MESSAGE", id);
    },
  },
  getters: {
    getAppMessages(state) {
      return state;
    },
  },
};
