import { unref } from 'vue';import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  id: "app-messages",
  class: "app-messages"
};
const _hoisted_2 = {
  class: "container pb-3 px-3"
};
const _hoisted_3 = {
  class: "items d-block"
};
const _hoisted_4 = {
  class: "row"
};
const _hoisted_5 = ["id"];
const _hoisted_6 = {
  key: 0,
  class: "col-12 pt-3 d-none d-md-block"
};
import AppMessage from '#/AppMessage';
import { clearAll, messages, unreadCount } from '@use/useAppMessages';
export default {
  name: 'AppMessages',

  setup(__props) {
    return (_ctx, _cache) => {
      return _unref(messages)?.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(messages), (message, messageIndex) => {
        return _openBlock(), _createElementBlock("div", {
          key: `message-${messageIndex}-${message.id}`,
          class: _normalizeClass(`col-12 ${message?.dismissedAt > 0 ? 'dismissed-message' : 'not-dismissed-message'}`),
          id: `app-message-${message.id}`
        }, [_createVNode(_unref(AppMessage), {
          message: message,
          "message-index": messageIndex,
          "messages-length": _unref(messages).length
        }, null, 8, ["message", "message-index", "messages-length"])], 10, _hoisted_5);
      }), 128)), _unref(unreadCount) > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("button", {
        type: "button",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(clearAll) && _unref(clearAll)(...args)),
        "aria-label": "Clear All Alerts",
        class: "btn btn-dark btn-sm rounded-pill clear-all"
      }, "Clear All")])) : _createCommentVNode("", true)])])])])) : _createCommentVNode("", true);
    };
  }

};