// We'll create a classConfig object that contains the class lists we want
// for each sectionKey within a given input type

// if we want to return some classes for _all_ section keys regardless of input type
// we can put them here to later combine with any more specific section output.

// Create some re-useable definitions because
// many input types are identical in how
// we want to style them.
const all = {
  outer: "outer field",
  inner: "inner",
  input: "input",
  error: "error",
  label: "label text-1000",
  legend: "legend  text-1000",
  help: "help",
  messages: "messages list-unstyled p-0 mb-0 mt-2 mx-0 d-block",
  message: "message text-danger my-0",
  legend: "legend",
  fieldset: "fieldset",
  wrapper: "wrapper",
};
const createDefaults = (type, options = {}) => {
  return Object.keys(all).reduce((acc, key) => {
    const ogCls = all[key];
    const Trim = (trimStr = " ") => {
      const startEndWhitespaceRegex = /^\s*|\s*$/g;
      const result =
        typeof trimStr != "string"
          ? null
          : trimStr.replace(startEndWhitespaceRegex, "").replace(/\s+/g, " ");
      if (result === "") {
        return null;
      }
      return result;
    };
    const optCls = typeof options[key] != "undefined" ? options[key] : "";
    const cls = `${key} ${type}-${key} is-${type} is-${type} ${
      key == "outer" ? type + "-field " : ""
    } ${ogCls} ${optCls}`;
    let arr = cls
      .split(" ")
      .map((str) => Trim(str))
      .filter((str) => !!str);
    const classList = [...new Set(arr)].join(" ");
    acc[key] = classList;
    return acc;
    // acc[key] = cls;
    // return acc;
    // acc[key] = cls;
    // let ogArr;
    // try {
    //   ogArr = og.split(" ");
    // } catch (e) {
    //   ogArr = [og];
    // }
    // let option = typeof options[key] === "string" ? options[key] : null;

    // const arr = [
    //   `${key} ${type}-field ${type}-outer`,
    //   `${type}-${key} ${option ? option : ""}`,
    //   ...ogArr,
    // ]
    //   .map((c) => {
    //     const startEndWhitespaceRegex = /^\s*|\s*$/g;

    //     const result = `${c}`
    //       .replace(/\s+/g, " ")
    //       .replace(startEndWhitespaceRegex, "");
    //     return result;
    //   })
    //   .filter((c) => !!c);
    // const output = arr.join(" ");
    // return { ...acc, [key]: output };
  }, {});
};

export const checkLike = {
  inner: "my-0",
  label: "d-block cursor-pointer form-check-label",
  wrapper: "form-check d-flex align-items-center",
  input: "my-auto",
};
export const textLike = { input: "form-control", label: "form-label" };
export const text = createDefaults("text", textLike);
export const textarea = createDefaults("textarea", textLike);
export const select = createDefaults("select", {
  label: "form-label",
  input: "form-select",
});
export const checkbox = createDefaults("checkbox", {
  ...checkLike,

  label: "form-check-label",
  input: "form-check-input",
});
export const radio = createDefaults("radio", {
  ...checkLike,

  label: "form-check-label",
  input: "form-check-input",
});
export const toggle = createDefaults("toggle", {
  ...checkLike,

  outer: "",
  inner: "",
  label: "form-check-label",
  input: "form-check-input",
  wrapper: "wrapper toggle-wrapper form-check form-switch",
  help: "help toggle-help",
  error: "error toggle-error",

  //   type: "checkbox",
  //   role: "switch",
  wrapper: `${checkLike.wrapper} form-check form-switch`,
});

//   input: "input toggle-input form-check-input",
//   wrapper: "wrapper toggle-wrapper form-check form-switch",
//   help: "help toggle-help",
//   error: "error toggle-error",

export const buttonClassification = createDefaults("button", {
  outer: "button",
  input: "btn btn-primary d-block",
});

// export our definitions using our above
// templates and declare one-offs and
// overrides as needed.
export const configs = {
  all: {
    ...all,
  },
  button: buttonClassification,
  //   color: {
  //     inner: "d-block col-2",
  //     wrapper: "d-flex flex-column",
  //     label: "d-block px-3 flex-fill",
  //     // label: "d-block mb-2  small",
  //   },
  checkbox: checkbox,
  email: { ...text, ...createDefaults("email", textLike) },
  range: createDefaults("file"),
  range: createDefaults("color"),
  radio: radio,
  text: text,
  textarea,
  select,
  submit: buttonClassification,

  month: { ...text, ...createDefaults("month", textLike) },
  number: { ...text, ...createDefaults("number", textLike) },
  password: { ...text, ...createDefaults("password", textLike) },
  search: { ...text, ...createDefaults("search", textLike) },
  tel: { ...text, ...createDefaults("tel", textLike) },
  time: { ...text, ...createDefaults("time", textLike) },
  url: { ...text, ...createDefaults("url", textLike) },
  week: { ...text, ...createDefaults("week", textLike) },

  date: { ...text, ...createDefaults("date", textLike) },
  "datetime-local": { ...text, ...createDefaults("datetime-local", textLike) },
  range: createDefaults("range"),
};
export default configs;

//   color: {
//       label: 'col-10',
//       inner: '',
//       input: 'form-control form',
//   }
//   file: {
//     outer: "file-outer",
//     label: "d-block mb-1  text-sm",
//     inner: "col-4 cursor-pointer",
//     wrapper: "d-flex w-100",
//     label: "flex-fill",
//     // label: "px-3 flex-fill",
//     // input:
//     //   "text-muted mb-1  file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-blue-500 file:text-white hover:file:bg-blue-600",
//     noFiles: "d-block small mb-1",
//     fileItem: "d-flex text-dark small mb-1",
//     removeFiles: "ms-auto text-primary small",
//   },
