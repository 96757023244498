import { createRouter, createWebHistory } from "vue-router";

// import TestingView from "@views/testing/TestingView";

import ToolView from "@views/ToolView";
import ToolsView from "@views/ToolsView";
// import LoginView from "@views/dashboard/LoginView";
// import DashboardView from "@views/dashboard/DashboardView";
const routes = [
  {
    path: "/",
    redirect: "/tools/mx-connect-config-generator",
  },
{
    path: "/tools",
    redirect:"/tools/mx-connect-config-generator"
},
  {
    path: "/tools/:toolId",
    name: "tool",
    component: ToolView,
  },
  {
    path: "/tools/:toolId/:modeId",
    name: "toolmode",
    component: ToolView,
  },
  {
    path: "/tools/kit",
    name: "toolkit",
    component: ToolsView,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
export { router, routes };
