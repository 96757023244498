import { unref, resolveComponent } from 'vue';import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import AppMessages from '#/AppMessages.vue';
import TheNav from "#/TheNav";
import { useTitle } from '@use/useTitle';
export default {
  name: 'App',

  setup(__props) {
    useTitle('Home'); // import { auth } from "@use/useFirebase";

    const showDev = true;
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_unref(TheNav)), _createVNode(_component_router_view, {
        class: _normalizeClass(`${showDev ? '' : 'no-dev'}`)
      }, null, 8, ["class"]), _createVNode(AppMessages)], 64);
    };
  }

};